import { motion } from "framer-motion"
import Link from "next/link"
import { useMediaPredicate } from "react-media-hook"
import { useState, useEffect, useRef } from "react"
import Falcon from "@/common/logos/falcon"
import Arrow from "@/common/icons/arrow"
import styles from "./event.module.css"
import Image from "next/image"

export default function Event({ post, trackClick = () => {} }) {
  const isMobile = useMediaPredicate("(max-width: 768px)")
  const [hasMounted, setMounted] = useState(false)
  const [innerHeight, setInnerheight] = useState(100)
  const contentRef = useRef(null)
  const contentMinHeight = 138

  // This is used due to isMobile not working before mount
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setMounted(true)
      if (contentRef.current) {
        setInnerheight(contentRef.current.firstChild.offsetHeight + 12)
      }
    }
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!post) {
    return null
  }

  const excerptVariants = {
    initial: {
      translateY: 0,
      transition: {
        duration: 0.2,
        ease: "easeIn"
      }
    },
    hover: {
      translateY: hasMounted && isMobile ? 0 : -innerHeight + contentMinHeight,
      transition: {
        duration: 0.2,
        ease: "easeIn"
      }
    }
  }

  return (
    <motion.div
      className={styles.card}
      initial="initial"
      whileHover="hover"
      animate="initial">
      <Link href={post.url} prefetch={false}>
        <a onClick={(e) => trackClick(e, post.url)}>
          <div className="inner-wrapper">
            <div className="header">
              <Falcon className="w-10 h-10 rounded-full bg-summer-green-100/50" />
              <div className="text-wrapper">
                <span className="organizer">{post.organizer}</span>
              </div>
            </div>
            <figure className="image-wrapper">
              <Image
                alt={post.image.alt}
                src={post.image.url}
                sizes={post.image.sizes}
                layout="fill"
              />
            </figure>
            <div
              className="content-wrapper"
              ref={contentRef}
              style={{ minHeight: contentMinHeight }}>
              <motion.div className="motion-elem" variants={excerptVariants}>
                <div className="tag">{post.type.name}</div>
                <h3>{post.title}</h3>
                <p className="dateline">
                  <strong>Datum:</strong> {post.dateString} &bull;{" "}
                  {post.timeString}
                </p>
                {post.location && (
                  <p>
                    <strong>Plats:</strong> {post.location}
                  </p>
                )}
                <p className="excerpt">{post.excerpt}</p>
              </motion.div>
            </div>
            <div className="footer-wrapper">
              <span>Mer om evenemanget</span>
              <div className="arrow">
                <Arrow className="w-3" />
              </div>
            </div>
          </div>
        </a>
      </Link>
    </motion.div>
  )
}
